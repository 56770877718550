import './App.css';

import LSystem from './components/Lsystem';
function App() {
   var LSystemStrings =[ 
`A(g=0) -> 
mov(random(100/(g+1)-10,100/(g+1)+10),5/(g+1))
rot((3/(g+1))*cos(context(0)))
[rot(random(-25,-15))A(g=g+1)] 
rot((3/(g+1))*cos(context(0)*0.7))
[rot(random(15,25))A(g=g+1)]
rot((3/(g+1))*sin(context(0)*0.7))`,
`A() ->
B()
rot(-22.5)
rot(sin(context(0)*0.5)*0.5)
[[A()]rot(22.5)rot(sin(context(0)*0.5)*0.5)A()]
rot(22.5)
B()
[rot(22.5)rot(cos(context(0)*0.7)*0.3)B()A()]
rot(-22.5)
A(),
B() ->
mov(3,1)
B()
B()`,
`A() ->
B()
[rot(20)rot(sin(context(0)*0.6))A()]
B()
[rot(-20)rot(cos(context(0)*0.9))A()]
rot(20)
rot(sin(context(0)*0.9))
A(),
B() ->
mov(1,1)
B()
B()`
];

   return (
      <div className="App">
         <header className="App-header">
	    <LSystem lsystemstrings={LSystemStrings} lstring={LSystemStrings[0]} depths={[12,4,6]} />
         </header>
      </div>
   );
}

export default App;
