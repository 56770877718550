import React, { Component } from 'react';

import { parse as LParser } from '../lsystem';
import { LevolveSystems, LexportAsString } from '../L';
import Turtle from './turtle.jsx';
import LSystemInput from './lsystemInput.jsx';

class LSystem extends Component {

	constructor(props) {
		super(props);
		this.state = {
			LSystemStrings: this.props.lsystemstrings,
         lstring: null,
         lsystems: [],
         error: <div></div>
		};
		this.handleNewLSystem = this.handleNewLSystem.bind(this);
      this.handleNewInput = this.handleNewInput.bind(this);
      this.index = 0;
	}

	componentDidMount() {
      var LSystemStrings = this.state.LSystemStrings;
      var new_lsystems = [];
      console.log("Mounting..");
      for ( var i = 0; i < LSystemStrings.length; i++) {
   		var result = LParser(LSystemStrings[i]);
		   LevolveSystems(result, this.props.depths[i]);
         var lsystemExpanded = result[0].lstring;
         new_lsystems.push({"expanded": lsystemExpanded, "lstring": lsystemExpanded});
      }
      console.log("Mounted..");
   	this.setState({
         lsystemExpanded: new_lsystems[this.index]["expanded"],
         lstring: new_lsystems[this.index]["lstring"]
      });

      this.interval = setInterval(() => {
         this.index = (this.index + 1)%new_lsystems.length;
         this.canvas = document.getElementById("canvas");
         this.canvas.classList.add("fade-out");
         setTimeout(() => {
   	      this.setState({
               lsystemExpanded: new_lsystems[this.index]["expanded"],
               lstring: new_lsystems[this.index]["lstring"]
            });
            this.canvas = document.getElementById("canvas");
            this.canvas.classList.remove("fade-out");
            this.canvas.classList.add("fade-in");
         }, 1000);
      }, 15000);
	}

   componentWillUnmount() {
      clearInterval(this.interval);
   }

	handleNewLSystem(newLsystem, depth) {
		var LSystemString = newLsystem;
      try {
		var result = LParser(LSystemString);
      } catch(e) {
        this.setState({
           error: (<small style={{color: "red"}}>Parsing error:<br/> {"" + e}</small>)
        });
        return;
      }
		LevolveSystems(result, depth);
		this.setState({
			LSystemString: newLsystem,
         lstring: result[0].lstring,
		   error: ""
      });
	}

	handleNewInput(val) {
		this.setState({
			currentText: val.target.value
		});
	}

	render() {
		if (this.state.lstring == null) {
			return "<p>Loading...</p>";
		}
		return (
			<div style={{position:"fixed", zIndex:"inherit" }}>
				<Turtle lstring={this.state.lstring} />
<div>
<section>
<h2>Rickard Hallerbäck</h2>
<a href="mailto:rickard.hallerback@gmail.com">rickard.hallerback@gmail.com</a>
<a href="tel:+46-70-392-6294">+4670-392 62 94</a>
M. Sc. Eng. Lund University, grad. 2018
</section>
    <section>
        <h2>Software Engineer</h2>
        <a href="https://se.linkedin.com/in/rickard-hallerb%C3%A4ck-b6744b153">LinkedIn</a>
        <a href="https://github.com/Ricardicus">GitHub</a>
    </section>
    <section>
        <h2>Music</h2>
        <a href="https://soundcloud.com/rickard_hallerback">Soundcloud</a>
	<a href="https://open.spotify.com/artist/0w1RATltKYLrntmPnchjus?si=8eF0L9jfSqi94me74GCedw&dl_branch=1">Spotify</a>
     </section>
     <section>
        <h2>Ongoing</h2>
	
	<a href="https://github.com/Ricardicus/keyboard-synth">Keyboard synth</a>    
	<a href="https://github.com/Ricardicus/image-colorizer">Image colorizer</a>
	<a href="https://ricardicus.se">Online terminal for my programming language</a>
      </section>

<section><h2>PGP key</h2><small><a href="/pgp/key.asc">D54F ED01 913E 2120 AD9D B3CB B072 6D47 81F9 79F7</a></small></section>
</div>
			</div>);
	}

}

export default LSystem;
